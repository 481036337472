import styled from 'styled-components'
import { media } from 'constants/media/media'

export const BasicPageWrapper = styled.div`
	margin: 3rem 0;

	${media.tablet`
		margin: 5rem 0;
	`}

	${media.desktopSmall`
		margin: 8rem 0;
	`}

	${media.desktopLarge`
		margin: 10rem 0;
	`}
`
