import React, { useEffect } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { Columns, Column, PageContainerContent } from 'components/base/grid/grid'
import { BasicPageWrapper } from 'components/base/sections/pageWrappers'
import NotFound from 'components/notFound/'
import { H1 } from 'components/base/typography/headings'
import { Client } from 'prismicConfiguration'
import { Document } from 'prismic-javascript/d.ts/documents'
import DynamicPage from 'interfaces/prismic/dynamicPage'
import { Flex } from 'rebass'
import { media } from 'constants/media/media'
import { RichTextStyleWrapper } from 'components/base/typography/richTextStyleWrapper'
import styled from 'styled-components'

// @ts-ignore
import { RichText } from 'prismic-reactjs'
interface ImageProps {
	image: string
}

const StyledImageWrapper = styled(Column)`
	${media.tabletAndMobileOnly`
		display: none;
	`}
`

const StyledImage = styled.div<ImageProps>`
	width: 100%;
	height: 500px;
	background-size: cover;
	background-position: 50% 50%;
	background-image: url(${({ image }) => image});
`

interface Page extends Document {
	data: DynamicPage
}
interface DynamicPageProps {
	page: Page | null
}

const DynamicContentPage: NextPage<DynamicPageProps> = ({ page }) => {
	const router = useRouter()

	if (!page) {
		return <NotFound />
	}

	return (
		<BasicPageWrapper>
			<PageContainerContent>
				{page?.data.title && page?.data.content && (
					<Columns>
						<Flex justifyContent="space-between" mt="3rem">
							<Column flex="1" desktopSmall={9 / 16}>
								<H1 mb="3rem">{RichText.asText(page.data.title)}</H1>
								<RichTextStyleWrapper>{RichText.render(page.data.content)}</RichTextStyleWrapper>
							</Column>
							{page?.data.image && (
								<StyledImageWrapper tablet={6 / 16}>
									<StyledImage image={page.data.image.url} />
								</StyledImageWrapper>
							)}
						</Flex>
					</Columns>
				)}
			</PageContainerContent>
		</BasicPageWrapper>
	)
}

DynamicContentPage.getInitialProps = async context => {
	const { query } = context
	try {
		const page = await Client().getByUID('content_page', `${query.dynamicPage}`, {})
		return { page, label: page?.data?.title ? RichText.asText(page.data.title) : '' }
	} catch (error) {
		return { page: null }
	}
}

export default DynamicContentPage
